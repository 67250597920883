<template>
  <v-container fluid>
    <AreYouSureModal resource="analysis" prefix="custom" :id="selectedId" :dialog="deleteDialog" @toggle="toggleModal" />
    <success-error-modal :dialog="dialog" :success="success" :successMessage="successMessage" :errorData="errorData"
      @toggleModal="toggleIngestionModal"></success-error-modal>
    <div class="d-flex flex-column">
      <v-layout child-flex>
        <v-card class="ma-2 d-flex flex-column">
          <v-data-table :footer-props="{ 'items-per-page-text': '' }" :no-data-text="$t('No data available')"
            :headers="headers" :items="filteredSubmisisons" :items-per-page="15" :show-select="true" :search="search"
            :single-select="true" class="elevation-1" v-model="selectedSubmissions">
            <template v-for="header in nonSlotHeaders" v-slot:[`item.${header.value}`]="{ item }">
              <a class="normal-text" :key="header.value"
                :href="`https://odk.bms.mpwt.link/#/projects/2/forms/${selectedFormId}/submissions/${item.instanceID}`">{{
                  item[header.value] }}</a>
            </template>
            <template v-slot:[`item.province`]="{ item }">
              <div v-if="item.province">
                <a class="normal-text"
                  :href="`https://odk.bms.mpwt.link/#/projects/2/forms/${selectedFormId}/submissions/${item.instanceID}`">{{
                    $store.state.adm.provincesList.find(
                      (x) => x.id == item.province
                    )[description]
                  }}</a>
              </div>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Submissions") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="green" dark class="mb-2" :loading="loading" @click="ingestSelectedSumissions">{{
                  $t("Ingest") }}</v-btn>
                <v-text-field v-model="search" class="mx-4" append-icon="mdi-magnify" :label="$t('Search')" single-line
                  hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-select :label="$t('Form')" v-model="selectedFormId" :clearable="true" class="mt-4 mx-2" dense
                  :items="formList">
                </v-select>
                <v-select :label="$t('Status')" v-model="selectedStatus" :clearable="true" class="mt-4 mx-2" dense
                  :items="statusList" item-text="label" item-value="id">
                </v-select>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <a
                    :href="`https://odk.bms.mpwt.link/#/projects/2/forms/${selectedFormId}/submissions/${item.instanceID}`"><v-icon
                      color="blue" medium class="mr-2" v-bind="attrs" v-on="on">
                      mdi-note-text-outline
                    </v-icon>
                  </a>
                </template>
                <span>View analysis</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-layout>
      <v-layout child-flex>
        <v-card class="ma-2 pa-2 d-flex">
          <v-select class="ma-2 status-updater-selector" :width="300" :label="$t('Status')" v-model="newStatus" dense
            :items="statusList" item-text="label" item-value="id"></v-select>
          <v-btn color="green" dark class="ma-2" :loading="loading" @click="changeStatusSelectedSumissions">{{
            $t("Update") }}</v-btn>
        </v-card>
      </v-layout>
    </div>
  </v-container>
</template>
<script>
import BridgeService from "@/services/BridgeService";
import AreYouSureModal from "@/components/main/AreYouSureModal.vue";
import router from "@/router";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";
import StaticDataService from "@/services/StaticDataService.js";

export default {
  name: "TheSubmissionsList",
  components: {
    AreYouSureModal,
    SuccessErrorModal,
  },
  data() {
    return {
      search: "",
      deleteDialog: false,
      dialog: false,
      dialogMessage: true,
      showArchived: false,
      submissions: [],
      loading: false,
      selectedId: null,
      selectedStatus: "received",
      newStatus: "received",
      formList: [],
      selectedFormId: null,
      selectedSubmissions: [],
      success: true,
      successMessage: {
        en: "",
        la: "",
      },
      errorData: "",
    };
  },
  props: ["submissionType"],
  computed: {
    statusList() {
      return [{
        id: "received",
        label: this.$t('received')
      }, {
        id: "rejected",
        label: this.$t('rejected')
      }, {
        id: "hasIssues",
        label: this.$t('hasIssues')
      }, {
        id: "approved",
        label: this.$t('approved')
      }]
    },
    tableName() {
      if (this.submissionType === 'inventory') {
        return 'inventory_form';
      }
      return 'inspection_form';
    },
    filteredSubmisisons() {
      if (this.selectedStatus === null) {
        return this.submissions;
      }
      return this.submissions.filter(
        (s) => s.reviewState === this.selectedStatus
      );
    },
    headers() {
      return [
        {
          text: this.$t("InstanceID"),
          value: "instanceID",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("submissionDate"),
          value: "submissionDate",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("province"),
          value: "province",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("reviewState"),
          value: "reviewState",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("road_number"),
          value: "road_number",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("chainage"),
          value: "chainage",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("bridge_name"),
          value: "bridge_name",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("direction"),
          value: "direction",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("inspector_name"),
          value: "inspector_name",
          sortby: true,
          color: "blue",
          slot: false,
        },
        {
          text: this.$t("inspection_date"),
          value: "inspection_date",
          sortby: true,
          color: "blue",
          slot: false,
        },

        { text: "", value: "actions", color: "blue" },
      ];
    },
    nonSlotHeaders() {
      return this.headers.filter(x => x.slot === false);
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },

  },
  watch: {
    submissionType() {
      this.init();
    },
    selectedFormId() {
      this.getSubmissionsList();
    },
  },
  methods: {
    async init() {
      this.formList = (await StaticDataService.index(this.tableName)).data.map(x => x.name);
      if (this.formList.length) {
        this.selectedFormId = this.formList[0];
      };
    },
    async ingestSelectedSumissions() {
      try {
        if (this.selectedSubmissions.length && this.selectedFormId) {
          this.loading = true;
          await BridgeService.ingestSubmission(
            this.submissionType,
            this.selectedFormId,
            this.selectedSubmissions[0].id
          ).catch(err => {
            if (err.response) {
              console.log(err.response)
              throw new Error(err.response.data?.error)
            }
            else {
              throw new Error(err)
            }
          });
          this.loading = false;
          this.success = true;
          this.dialog = true;
          this.successMessage = {
            en: "Submission successfully ingested",
            la: "Submission successfully ingested",
          };
          this.getSubmissionsList();
        } else {
          this.success = false;
          this.dialog = true;
          this.errorData = "Please select a submission";
        }
      } catch (err) {
        console.log(err);
        this.success = false;
        this.dialog = true;
        this.loading = false;
        this.errorData = err;
      }
    },
    async changeStatusSelectedSumissions() {
      try {
        if (this.selectedSubmissions.length && this.selectedFormId) {
          this.loading = true;
          await BridgeService.changeReviewStatus(
            this.selectedFormId,
            this.selectedSubmissions[0].id,
            this.newStatus,
          ).catch(err => {
            if (err.response) {
              console.log(err.response)
              throw new Error(err.response.data?.error)
            }
            else {
              throw new Error(err)
            }
          });
          this.loading = false;
          this.success = true;
          this.dialog = true;
          this.successMessage = {
            en: "Submission status successfuly updated",
            la: "Submission status successfuly updated",
          };
          this.getSubmissionsList();
        } else {
          this.success = false;
          this.dialog = true;
          this.errorData = "Please select a submission";
        }
      } catch (err) {
        console.log(err);
        this.success = false;
        this.dialog = true;
        this.loading = false;
        this.errorData = err;
      }
    },
    async toggleModal(id) {
      if (!this.deleteDialog) {
        this.selectedId = id;
        this.deleteDialog = true;
      } else {
        this.selectedId = null;
        this.deleteDialog = false;
        this.getSubmissionsList();
      }
    },
    async toggleIngestionModal() {
      if (!this.dialog) {
        this.dialog = true;
      } else {
        this.dialog = false;
        this.getSubmissionsList();
      }
    },
    showDetails(item) {
      router.push({
        name: "analysis-details",
        params: { id: item.id },
      });
    },
    async getSubmissionsList() {
      this.submissions = (
        await BridgeService.indexSubmissions(this.selectedFormId)
      ).data.map((x) => {
        let chainage = '';
        if (x["BGINF"]) {
          chainage = x["BGINF"].km ? x["BGINF"].km : x["BGINF"].chainage;
        }
        if (!chainage) {
          chainage = x["BRDE2"].km ? x["BRDE2"].km : x["BRDE1"].chainage;
          if (chainage === 'OTHER') {
            chainage = x["BRDE1"].km
          }
        }
        return {
          id: x.__id,
          instanceID: x.meta.instanceID,
          submitterName: x.__system.submitterName,
          submissionDate: x.__system.submissionDate,
          reviewState: x.__system.reviewState ?? "received",
          road_number: x.RDINF.road_number,
          road_link: x.RDINF.road_link,
          chainage,
          direction: x.BGINF ? x.BGINF.direction : x.BRDE1.direction,
          bridge_name: x.BGINF ? x.BGINF.bridge_name : x.BRDE1.bridge_name,
          inspector_name: x.BGINF ? x.BGINF.inspector_name : x["UPDAT"]?.update_by,
          inspection_date: x.BGINF ? x.BGINF.inspection_date : x["UPDAT"]?.update_date,
          province: x.BGINF ? x.BGINF.provinces : x["BRDE1"] ? x["BRDE1"].provinces : "",
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style scoped>
.status-updater-selector {
  max-width: 300px;
}</style>