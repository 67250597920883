var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('AreYouSureModal',{attrs:{"resource":"analysis","prefix":"custom","id":_vm.selectedId,"dialog":_vm.deleteDialog},on:{"toggle":_vm.toggleModal}}),_c('success-error-modal',{attrs:{"dialog":_vm.dialog,"success":_vm.success,"successMessage":_vm.successMessage,"errorData":_vm.errorData},on:{"toggleModal":_vm.toggleIngestionModal}}),_c('div',{staticClass:"d-flex flex-column"},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"ma-2 d-flex flex-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text': '' },"no-data-text":_vm.$t('No data available'),"headers":_vm.headers,"items":_vm.filteredSubmisisons,"items-per-page":15,"show-select":true,"search":_vm.search,"single-select":true},scopedSlots:_vm._u([_vm._l((_vm.nonSlotHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('a',{key:header.value,staticClass:"normal-text",attrs:{"href":("https://odk.bms.mpwt.link/#/projects/2/forms/" + _vm.selectedFormId + "/submissions/" + (item.instanceID))}},[_vm._v(_vm._s(item[header.value]))])]}}}),{key:"item.province",fn:function(ref){
var item = ref.item;
return [(item.province)?_c('div',[_c('a',{staticClass:"normal-text",attrs:{"href":("https://odk.bms.mpwt.link/#/projects/2/forms/" + _vm.selectedFormId + "/submissions/" + (item.instanceID))}},[_vm._v(_vm._s(_vm.$store.state.adm.provincesList.find( function (x) { return x.id == item.province; } )[_vm.description]))])]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Submissions")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","dark":"","loading":_vm.loading},on:{"click":_vm.ingestSelectedSumissions}},[_vm._v(_vm._s(_vm.$t("Ingest")))]),_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{staticClass:"mt-4 mx-2",attrs:{"label":_vm.$t('Form'),"clearable":true,"dense":"","items":_vm.formList},model:{value:(_vm.selectedFormId),callback:function ($$v) {_vm.selectedFormId=$$v},expression:"selectedFormId"}}),_c('v-select',{staticClass:"mt-4 mx-2",attrs:{"label":_vm.$t('Status'),"clearable":true,"dense":"","items":_vm.statusList,"item-text":"label","item-value":"id"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{attrs:{"href":("https://odk.bms.mpwt.link/#/projects/2/forms/" + _vm.selectedFormId + "/submissions/" + (item.instanceID))}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue","medium":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-text-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("View analysis")])])]}}],null,true),model:{value:(_vm.selectedSubmissions),callback:function ($$v) {_vm.selectedSubmissions=$$v},expression:"selectedSubmissions"}})],1)],1),_c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"ma-2 pa-2 d-flex"},[_c('v-select',{staticClass:"ma-2 status-updater-selector",attrs:{"width":300,"label":_vm.$t('Status'),"dense":"","items":_vm.statusList,"item-text":"label","item-value":"id"},model:{value:(_vm.newStatus),callback:function ($$v) {_vm.newStatus=$$v},expression:"newStatus"}}),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"green","dark":"","loading":_vm.loading},on:{"click":_vm.changeStatusSelectedSumissions}},[_vm._v(_vm._s(_vm.$t("Update")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }